import highlight from "@/Client/Kotak/pages/calendar/utils/highligts";

export default {
  data() {
    return {
      highlights: highlight
    };
  },
  computed: {
    moneyMarket() {
      return date => {
        return this.getDayData(date) ? this.getDayData(date).moneyMarket : [];
      };
    },
    internationalData() {
      return date => {
        return this.getDayData(date)
          ? this.getDayData(date).internationalData
          : [];
      };
    },
    marketLastYear() {
      return date => {
        return this.getDayData(date)
          ? this.getDayData(date).marketLastYear &&
            this.getDayData(date).marketLastYear.length
            ? this.getDayData(date).marketLastYear[0]
            : ""
          : [];
      };
    }
  },
  methods: {
    getDayData(date) {
      if (!this.events.length) return false;
      const index = this.events.findIndex(x => x._id === date);
      return this.events[index];
    },
    getDate(year, month, day) {
      const date = new Date(year, month, day);
      date.setHours(0, -date.getTimezoneOffset(), 0, 0);
      return date.toISOString();
    },
    highlightData(date, event) {
      if (this.getDayData(date)) {
        const index = this.getDayData(date).internationalData.findIndex(
          x => x.event === event
        );
        if (index !== -1) {
          return (
            this.highlights.findIndex(
              x =>
                x.Events ===
                this.getDayData(date).internationalData[index].event
            ) !== -1
          );
        }
      } else {
        return false;
      }
    },
    async getCalendarData(startDate, endDate) {
      const payload = {
        startDate: startDate,
        endDate: endDate
      };

      try {
        this.openLoadingInDiv();

        const data = await this.$store.dispatch(
          "calendar/getMoneyMarket",
          payload
        );

        this.closeLoadingInDiv();

        this.setCalendarData(data);

        this.$vs.loading.close();
      } catch (error) {
        if (error) this.$vs.loading.close();

        this.isError = true;
      }
    },
    openLoadingInDiv() {
      this.isLoading = true;
      const element = document.getElementById("div-with-loading");
      if (element)
        this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
          type: "sound"
        });
    },
    closeLoadingInDiv() {
      this.isLoading = false;
      this.$vs.loading.close("#div-with-loading > .con-vs-loading");
    },
    setCalendarData(data) {
      if (!data.length) return;
      const result = [];
      data.forEach(element => {
        const index = this.events.findIndex(x => x._id === element._id);
        if (index === -1) {
          result.push(element);
        }
      });
      this.$store.commit("calendar/SET_MONEY_MARKET_EVENTS", result);
    }
  }
};
