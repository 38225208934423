<template>
  <table class="table-auto mb-8 border-l-b-r">
    <thead>
      <tr>
        <th class="popup-header-table rounded-lg" rowspan="2" colspan="3">
          Money Market
        </th>
        <th class="popup-header-table rounded-lg" rowspan="2">
          International Data
        </th>
        <th class="popup-header-table rounded-lg" colspan="15">
          Market Last Year
        </th>
      </tr>
      <tr>
        <th class="bg-primary-trans text-black text-center fsize10" colspan="4">
          Currency Against
        </th>
        <th class="bg-primary-trans text-black text-center fsize10" colspan="5">
          Interest Rates %
        </th>
        <th class="bg-primary-trans text-black text-center fsize10" colspan="6">
          Others
        </th>
      </tr>
      <tr>
        <th class="bg-primary-trans text-black text-center fsize10">Inflow</th>
        <th class="bg-primary-trans text-black text-center fsize10">Amount</th>
        <th class="bg-primary-trans text-black text-center fsize10">Outflow</th>
        <th class="bg-primary-trans text-black text-center fsize10"></th>
        <th class="bg-primary-trans text-black text-center fsize10">EUR</th>
        <th class="bg-primary-trans text-black text-center fsize10">JPY</th>
        <th class="bg-primary-trans text-black text-center fsize10">GER</th>
        <th class="bg-primary-trans text-black text-center fsize10">INR</th>
        <th class="bg-primary-trans text-black text-center fsize10">
          NSE MIBOR
        </th>
        <th class="bg-primary-trans text-black text-center fsize10">
          G-Sec 10-Yr
        </th>
        <th class="bg-primary-trans text-black text-center fsize10">
          OIS 5-Yr
        </th>
        <th class="bg-primary-trans text-black text-center fsize10">
          Corp Bond AAA 5-Yr
        </th>
        <th class="bg-primary-trans text-black text-center fsize10">
          1-Yr INR fwd yield(%)
        </th>
        <th class="bg-primary-trans text-black text-center fsize10">
          US Libor 6-months
        </th>
        <th class="bg-primary-trans text-black text-center fsize10">
          Brent Oil($/bbl)
        </th>
        <th class="bg-primary-trans text-black text-center fsize10">
          Spot Gold ($/oz)
        </th>
        <th class="bg-primary-trans text-black text-center fsize10">
          CPI Inflation
        </th>
        <th class="bg-primary-trans text-black text-center fsize10">SENSEX</th>
        <th class="bg-primary-trans text-black text-center fsize10">
          System Liquidity
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="border-r text-center">
          <p
            class="fsize10 pl-2 text-black fontfamily-sans-serif"
            v-for="(item, index) in moneyMarket(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            )"
            :key="'mm-in-des-' + index"
          >
            {{ item.inflow_description }}
          </p>
        </td>
        <td class="border-r text-center">
          <p
            class="fsize10 text-black pr-2 text-right fontfamily-sans-serif"
            v-for="(item, index) in moneyMarket(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            )"
            :key="'mm-in-amt-' + index"
          >
            {{ item.inflow_amount }}
          </p>
        </td>
        <td class="border-r">
          <span
            class="pl-2 fontfamily-sans-serif fsize10 text-black"
            v-for="(item, index) in moneyMarket(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            )"
            :key="'mm-in-des-' + index"
          >
            {{ item.outflow_description }}</span
          >
        </td>
        <td class="border-r text-center">
          <p
            class="fsize10 fontfamily-sans-serif"
            v-for="(item, index) in internationalData(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            )"
            :key="'item-int-d-' + index"
            :class="{
              'text-primary': highlightData(
                getDate(
                  showDate.getFullYear(),
                  showDate.getMonth(),
                  showDate.getDate()
                ),
                item.event
              ),
              'text-black': !highlightData(
                getDate(
                  showDate.getFullYear(),
                  showDate.getMonth(),
                  showDate.getDate()
                ),
                item.event
              )
            }"
          >
            {{ item.event }}
          </p>
        </td>
        <td class="border-r text-center">
          <span class="fsize10 fontfamily-sans-serif text-black">{{
            marketLastYear(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            ).market_last_year_currency_EUR
          }}</span>
        </td>
        <td class="border-r text-center">
          <span class="fsize10  fontfamily-sans-serif text-black">{{
            marketLastYear(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            ).market_last_year_currency_JPY
          }}</span>
        </td>
        <td class="border-r text-center">
          <span class="fsize10  fontfamily-sans-serif text-black">{{
            marketLastYear(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            ).market_last_year_currency_GBP
          }}</span>
        </td>
        <td class="border-r text-center">
          <span class="fsize10  fontfamily-sans-serif text-black">{{
            marketLastYear(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            ).market_last_year_currency_INR
          }}</span>
        </td>
        <td class="border-r text-center">
          <span class="fsize10  fontfamily-sans-serif text-black">{{
            marketLastYear(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            ).market_last_year_interest_rates_NSE_MIBOR
          }}</span>
        </td>
        <td class="border-r text-center">
          <span class="fsize10  fontfamily-sans-serif text-black">{{
            marketLastYear(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            ).market_last_year_interest_rates_G_Sec_10_yr
          }}</span>
        </td>
        <td class="border-r text-center">
          <span class="fsize10  fontfamily-sans-serif text-black">{{
            marketLastYear(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            ).market_last_year_interest_rates_OIS_10_yr
          }}</span>
        </td>
        <td class="border-r text-center">
          <span class="fsize10  fontfamily-sans-serif text-black">{{
            marketLastYear(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            ).market_last_year_interest_rates_CorpBand_AAAA_5_Yrr
          }}</span>
        </td>
        <td class="border-r text-center">
          <span class="fsize10  fontfamily-sans-serif text-black">{{
            marketLastYear(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            ).market_last_year_interest_rates_1_Yr_INR_fwd
          }}</span>
        </td>
        <td class="border-r text-center">
          <span class="fsize10  fontfamily-sans-serif text-black">{{
            marketLastYear(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
  ).market_last_year_interest_rates_Overnight_SOFR
          }}</span>
        </td>
        <td class="border-r text-center">
          <span class="fsize10  fontfamily-sans-serif text-black">{{
            marketLastYear(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            ).market_last_year_others_Brent_Oil_per_bbl
          }}</span>
        </td>
        <td class="border-r text-center">
          <span class="fsize10  fontfamily-sans-serif text-black">{{
            marketLastYear(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            ).market_last_year_others_G_Sec_10_Yr
          }}</span>
        </td>
        <td class="border-r text-center">
          <span class="fsize10  fontfamily-sans-serif text-black">{{
            marketLastYear(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            ).market_last_year_others_Spot_Gold_per_oz
          }}</span>
        </td>
        <td class="border-r text-center">
          <span class="fsize10 fontfamily-sans-serif text-black">{{
            marketLastYear(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            ).market_last_year_others_CPI_Inflation
          }}</span>
        </td>
        <td class="border-r text-center">
          <span class="fsize10  fontfamily-sans-serif text-black">{{
            marketLastYear(
              getDate(
                showDate.getFullYear(),
                showDate.getMonth(),
                showDate.getDate()
              )
            ).market_last_year_others_SENSEX
          }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import moneyMarketMixin from "@/Client/Kotak/mixins/moneyMarketMixin";
import calendarMixin from "@/Client/Kotak/mixins/calendarMixin";

import { mapState } from "vuex";

export default {
  mixins: [moneyMarketMixin, calendarMixin],
  props: {
    showDate: {
      type: Date,
      default: new Date()
    }
  },
  computed: {
    ...mapState("calendar", {
      events: "moneyMarketEvents"
    })
  }
};
</script>
