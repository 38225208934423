import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const promotionsRepository = RepositoryFactory.get("promotions");

export default {
  data() {
    return {
      days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      months: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC"
      ],
      fullMonths: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      viewUniqueId: null
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    isMobileView() {
      return this.windowWidth <= 575;
    }
  },
  methods: {
    dayOfDate(year, month, day) {
      return new Date(year, month, day).getDay();
    },
    modifiedDayOfDate(date) {
      const week = new Date(date).getDay();
      return week > 0 ? week - 1 : 6;
    },
    stripHTML(html) {
      if (!html) return;
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html.replace(/<br>/g, "&#10;");
      return tmp.textContent || tmp.innerText || "";
    },
    getCurrentDate(date) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const now = new Date(year, month, day);
      now.setHours(0, -now.getTimezoneOffset(), 0, 0);
      return now.toISOString();
    },
    getMonday(date) {
      const d = new Date(date);
      const day = d.getDay();
      const diff = d.getDate() - day + (day == 0 ? -6 : 1);
      return new Date(d.setDate(diff)).toISOString().slice(0, 10);
    },
    checkLogin() {
      return new Promise(resolve => {
        if (localStorage.getItem("token")) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    },
    async addViewedPromotionLog(calendar) {
      const payload = {
        communityName: currentCommunityName.communityName,
        userId: this.token.uuid,
        postId: calendar,
        name: this.token.username,
        startTime: new Date()
          .toISOString()
          .replace("T", " ")
          .replace("Z", "")
      };
      const { data } = await promotionsRepository.addViewedPromotion(payload);
      if (data) {
        this.viewUniqueId = data.uniqueId;
      }
    },

    async updateViewedPromotionLog() {
      const payload = {
        communityName: currentCommunityName.communityName,
        uniqueId: this.viewUniqueId,
        endTime: new Date()
          .toISOString()
          .replace("T", " ")
          .replace("Z", "")
      };
      promotionsRepository.updateViewedPromotion(payload);
    },

    getWeekNumber(date) {
      const selectedDate = new Date(date);
      const firstDay = new Date(selectedDate.getFullYear(), 0, 1);
      const pastDays = (selectedDate - firstDay) / 86400000;
      return Math.ceil((pastDays + firstDay.getDay() + 1) / 7);
    },

    isOddWeek(date) {
      return this.getWeekNumber(date) % 2 == 0;
    },

    daysInMonth(iMonth, iYear) {
      return 32 - new Date(iYear, iMonth, 32).getDate();
    },

    getDateObject(showDate, day) {
      const year = showDate.getFullYear();
      const month = showDate.getMonth();
      return new Date(year, month, day);
    },

    getWeekOfMonth(date, showDate) {
      const selectedDate = date;
      if (this.isLastDayOfWeekInSameMonth(date, showDate)) return 1;

      const month = selectedDate.getMonth();
      const year = selectedDate.getFullYear();

      const lastDateOfMonth = new Date(year, month + 1, 0).getDate();
      const firstWeekday =
        new Date(year, month, 1).getDay() === 0 && lastDateOfMonth > 30 //31 day month with firstWeekDay as Sunday fix
          ? 6
          : new Date(year, month, 1).getDay();
      const offsetDate = selectedDate.getDate() + firstWeekday - 1;
      const index = 1; // start index at 0 or 1, your choice
      const weeksInMonth =
        index + Math.ceil((lastDateOfMonth + firstWeekday - 7) / 7);
      const week = index + Math.floor(offsetDate / 7);

      if (date || week < 2 + index) return week;
      return week === weeksInMonth ? index + 5 : week;
    },

    isLastDayOfWeekInSameMonth(date, showDate) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const lastDayOfWeek = day + 6;
      const futureDate = new Date(year, month, lastDayOfWeek);
      const futureMonth = futureDate.getMonth();
      const calendarMonth = showDate.getMonth();

      return month !== futureMonth && futureMonth === calendarMonth;
    }
  }
};
