export default [
  {
    Date: "2021-01-01",
    Events: "US Holiday: New Year's Day"
  },
  {
    Date: "2021-01-01",
    Events: "EZ Holiday: New Year's Day"
  },
  {
    Date: "2021-01-01",
    Events: "UK Holiday: New Year's Day"
  },
  {
    Date: "2021-01-01",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-01-02",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-01-03",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-01-07",
    Events: "US: FOMC Minutes; Nov Trade balance"
  },
  {
    Date: "2021-01-18",
    Events: "US Holiday: Birthday of Martin Luther King"
  },
  {
    Date: "2021-01-21",
    Events: "EZ: ECB Policy meeting"
  },
  {
    Date: "2021-01-21",
    Events: "JP: BOJ Policy meeting; Dec Trade balance"
  },
  {
    Date: "2021-01-27",
    Events: "US: FOMC Meeting"
  },
  {
    Date: "2021-02-04",
    Events: "UK: BOE Policy meeting"
  },
  {
    Date: "2021-02-05",
    Events: "IN: RBI Policy meeting"
  },
  {
    Date: "2021-02-11",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-02-15",
    Events: "US Holiday: Washingtons's Birthday"
  },
  {
    Date: "2021-02-18",
    Events: "US: FOMC Minutes; Jan Housing starts, Building permits"
  },
  {
    Date: "2021-02-23",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-03-11",
    Events: "EZ: ECB Policy meeting"
  },
  {
    Date: "2021-03-17",
    Events: "US: FOMC meeting; Feb Housing starts, Building permits"
  },
  {
    Date: "2021-03-18",
    Events: "UK: BOE Policy meeting"
  },
  {
    Date: "2021-03-19",
    Events: "JP: BOJ Policy meeting; Feb CPI"
  },
  {
    Date: "2021-03-20",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-04-02",
    Events: "EZ Holiday: Good Friday"
  },
  {
    Date: "2021-04-02",
    Events: "UK Holiday: Good Friday"
  },
  {
    Date: "2021-04-05",
    Events: "EZ Holiday: Good Friday UK Holiday: Easter Monday"
  },
  {
    Date: "2021-04-07",
    Events: "US: FOMC minutes; Feb Trade balance"
  },
  {
    Date: "2021-04-22",
    Events: "EZ: ECB Meeting"
  },
  {
    Date: "2021-04-27",
    Events: "JP: BOJ Policy meeting"
  },
  {
    Date: "2021-04-28",
    Events: "US: FOMC Meeting"
  },
  {
    Date: "2021-04-29",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-05-01",
    Events: "EZ Holiday: Labour Day"
  },
  {
    Date: "2021-05-03",
    Events: "UK Holiday: Early May bank holiday"
  },
  {
    Date: "2021-05-03",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-05-04",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-05-05",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-05-06",
    Events: "UK: BOE Policy meeting; Apr Services PMI"
  },
  {
    Date: "2021-05-09",
    Events: "EZ Holiday: Anniversary of Robert Schuman's Declaration"
  },
  {
    Date: "2021-05-13",
    Events: "EZ Holiday: Ascension Day"
  },
  {
    Date: "2021-05-19",
    Events: "US: FOMC minutes"
  },
  {
    Date: "2021-05-24",
    Events: "EZ Holiday: Whit Monday"
  },
  {
    Date: "2021-05-31",
    Events: "US Holiday: Memorial Day"
  },
  {
    Date: "2021-05-31",
    Events: "UK Holiday: Spring bank holiday"
  },
  {
    Date: "2021-06-03",
    Events: "EZ Holiday: Corpus Christi"
  },
  {
    Date: "2021-06-10",
    Events: "EZ: ECB Meeting"
  },
  {
    Date: "2021-06-16",
    Events: "US: FOMC Meeting; May Housing starts, Building permits"
  },
  {
    Date: "2021-06-18",
    Events: "JP: BOJ Policy meeting"
  },
  {
    Date: "2021-06-24",
    Events: "UK: BOE Policy meeting"
  },
  {
    Date: "2021-07-05",
    Events: "US Holiday: Independence Day"
  },
  {
    Date: "2021-07-07",
    Events: "US: FOMC minutes"
  },
  {
    Date: "2021-07-13",
    Events: "US: Jun CPI"
  },
  {
    Date: "2021-07-16",
    Events: "JP: BOJ Policy meeting"
  },
  {
    Date: "2021-07-22",
    Events: "EZ: ECB Meeting"
  },
  {
    Date: "2021-07-22",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-07-23",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-07-28",
    Events: "US: FOMC Meeting"
  },
  {
    Date: "2021-08-05",
    Events: "UK: BOE Policy meeting"
  },
  {
    Date: "2021-08-08",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-08-09",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-08-18",
    Events: "US: FOMC minutes"
  },
  {
    Date: "2021-08-30",
    Events: "UK Holiday: Summer bank holiday"
  },
  {
    Date: "2021-09-06",
    Events: "US Holiday: Labour day"
  },
  {
    Date: "2021-09-09",
    Events: "EZ: ECB Meeting"
  },
  {
    Date: "2021-09-20",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-09-21"
  },
  {
    Date: "2021-09-22",
    Events: "US: FOMC Meeting; Aug Existing home sales"
  },
  {
    Date: "2021-09-22",
    Events: "JP: BOJ Policy meeting"
  },
  {
    Date: "2021-09-23",
    Events: "UK: BOE Policy meeting"
  },
  {
    Date: "2021-09-23",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-10-03",
    Events: "EZ Holiday: Day of German Unity"
  },
  {
    Date: "2021-10-11",
    Events: "US Holiday: Columbus Day"
  },
  {
    Date: "2021-10-13",
    Events: "US: FOMC minutes; Sep CPI"
  },
  {
    Date: "2021-10-28",
    Events:
      "EZ: ECB Policy meeting; Oct Consumer confidence, Economic sentiment"
  },
  {
    Date: "2021-10-28",
    Events: "JP: BOJ Policy meeting"
  },
  {
    Date: "2021-11-01",
    Events: "EZ Holiday: All Saints' Day"
  },
  {
    Date: "2021-11-03",
    Events:
      "US: FOMC Meeting; Oct Services PMI; Sep Capital goods orders, Durable goods orders, Factory orders"
  },
  {
    Date: "2021-11-04",
    Events: "UK: BOE Policy meeting"
  },
  {
    Date: "2021-11-11",
    Events: "US Holiday: Veterans Day"
  },
  {
    Date: "2021-11-23",
    Events: "JP Holiday: Bank holiday"
  },
  {
    Date: "2021-11-24",
    Events:
      "US: FOMC minutes; 3QCY21 GDP, Core PCE; Nov U. of Mich. Sentiment; Oct Personal income, Personal spending, Personal deflator, New home sales"
  },
  {
    Date: "2021-11-25",
    Events: "US Holiday: Thanksgiving Day"
  },
  {
    Date: "2021-12-15",
    Events: "US: FOMC Policy meeting; Nov Retail sales"
  },
  {
    Date: "2021-12-16",
    Events: "EZ: ECB Policy meeting, Oct Trade balance"
  },
  {
    Date: "2021-12-16",
    Events: "UK: BOE Policy meeting"
  },
  {
    Date: "2021-12-17",
    Events: "JP: BOJ Policy meeting"
  },
  {
    Date: "2021-12-24",
    Events: "EZ Holiday: Christmas Day"
  },
  {
    Date: "2021-12-24",
    Events: "US Holiday: Christmas Day"
  },
  {
    Date: "2021-12-25",
    Events: "EZ Holiday: Christmas Day"
  },
  {
    Date: "2021-12-27",
    Events: "UK Holiday: Christmas"
  },
  {
    Date: "2021-12-28",
    Events: "UK Holiday: Boxing Day"
  },
  {
    Date: "2021-12-31",
    Events: "EZ: New Year's Eve"
  },
  {
    Date: "2021-12-31",
    Events: "JP Holiday: Bank holiday"
  }
];
