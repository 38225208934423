<template>
  <div>
    <div class="mb-4" v-if="noteLocal.note">
      <div class="flex  mb-4 justify-between items-center">
        <div class="flex ">
          <p class="h2-class mr-2 ">Sticky Note</p>
          <img
            src="@/Client/Kotak/img/pin.png"
            class="cursor-pointer mt-1 w-4 h-4"
          />
        </div>
        <div class="h-4">
          <feather-icon
            icon="Edit2Icon"
            class="hover:text-warning cursor-pointer mr-2 h-4 w-5"
            @click.stop="
              showEdit = !showEdit;
              editedNote = noteLocal.note;
            "
          >
          </feather-icon>
          <feather-icon
            icon="Trash2Icon"
            class="hover:text-primary w-5 h-4 cursor-pointer"
            @click.stop="handleDeleteNote"
          >
          </feather-icon>
        </div>
      </div>

      <p
        class="w-full sticky-note"
        v-if="!showEdit"
        v-html="addLineBreak(noteLocal.note)"
      ></p>
      <vs-textarea
        placeholder="Enter Note"
        class="w-full mt-1"
        v-model="editedNote"
        v-if="showEdit"
      />
      <div class="float-right" v-if="showEdit">
        <vs-button class="mt-6 " @click="handleEdit">Update</vs-button>
      </div>
    </div>
    <AddNote v-else @noteAdded="handleNoteAdded" :date="date" />
  </div>
</template>

<script>
import AddNote from "./AddNote";

export default {
  props: {
    note: {
      type: Object,
      required: true
    },
    date: {
      type: Date,
      required: true
    }
  },
  components: {
    AddNote
  },
  watch: {
    note(val) {
      this.noteLocal = val;
    }
  },
  data() {
    return {
      showEdit: false,
      editedNote: "",
      noteLocal: this.note
    };
  },
  methods: {
    notifyError() {
      this.$vs.notify({
        title: "Error",
        text: "Something went wrong",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "danger"
      });
    },
    async handleDeleteNote() {
      try {
        const payload = {
          id: this.noteLocal._id
        };
        this.$vs.loading();

        await this.$store.dispatch("note/deleteNote", payload);
        this.noteLocal = {};

        this.$vs.loading.close();
        this.$emit("noteDeleted");
      } catch (error) {
        if (error) {
          this.$vs.loading.close();
          this.notifyError();
        }
      }
    },

    async handleEdit() {
      try {
        const payload = {
          id: this.noteLocal._id,
          note: this.editedNote
        };
        this.$vs.loading();

        await this.$store.dispatch("note/updateNote", payload);

        this.$vs.loading.close();
        this.showEdit = false;
        this.$emit("noteEdited");
      } catch (error) {
        if (error) {
          this.$vs.loading.close();
          this.notifyError();
        }
      }
    },
    handleNoteAdded(data) {
      this.noteLocal = data[0];
    },

    addLineBreak(html) {
      return html.replace(/\n/g, "<br>");
    }
  }
};
</script>
