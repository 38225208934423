<template>
  <div class="">
    <div>
      <div class="addnote-ui flex">
        <p class="mr-3">Add Note</p>
        <img src="@/Client/Kotak/img/addnote.png" class="h-6 w-6" />
      </div>
      <vs-textarea
        name="displayName"
        placeholder="Enter Note"
        class="w-full mt-1"
        v-model="note"
      />
    </div>

    <div class="float-right">
      <vs-button class="mt-2 mr-4" :disabled="!validateAdd" @click="handleAdd"
        >Add</vs-button
      >
    </div>
  </div>
</template>

<script>
import jwt from "jsonwebtoken";

import calendarMixin from "@/Client/Kotak/mixins/calendarMixin";

export default {
  mixins: [calendarMixin],
  props: {
    date: {
      type: Date,
      required: true
    }
  },
  data() {
    return {
      note: "",
      token: {}
    };
  },
  computed: {
    validateAdd() {
      return this.note;
    }
  },
  methods: {
    async handleAdd() {
      if (!this.note) return;
      const payload = {
        date: this.getCurrentDate(this.date),
        note: this.note,
        userID: this.token.uuid
      };

      try {
        this.$vs.loading();

        const data = await this.$store.dispatch("note/addNote", payload);

        this.$store.commit("note/SET_NOTES", data);

        this.$emit("noteAdded", data);

        this.$vs.loading.close();
        this.reset();
      } catch (error) {
        if (error) {
          this.$vs.notify({
            title: "Error",
            text: "something went wrong",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
          this.$vs.loading.close();
        }
      }
    },
    reset() {
      this.note = "";
    }
  },
  mounted() {
    let newtoken = localStorage.getItem("token");
    this.token = jwt.decode(newtoken);
  }
};
</script>
